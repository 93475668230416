import { useLazyQuery } from '@apollo/client';
import { HOUSE_TYPES_NL, Solution } from '@energiebespaarders/constants';
import { Radio, RadioGroup, Separator, Spinner } from '@energiebespaarders/symbols';
import { Large, Orange, Red, Small } from '@energiebespaarders/symbols/helpers';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LEAD_HOUSE_BY_ID } from '~/queries/house';
import { leadHouseById, leadHouseByIdVariables } from '~/types/generated/leadHouseById';
import { isBetween } from '~/utils/isBetween';
import SolutionChecklist, { SolutionOption } from './SolutionChecklist';

// Ugly, but otherwise React complains about uncontrolled inputs when leaving radios undefined on mount
type NullableBoolean = 'true' | 'false' | '';
type LeadFilterFormValues = {
  isRenting: NullableBoolean;
  inVve: NullableBoolean;
  isMonument: NullableBoolean;
};

export type LeadFilterQuestion = keyof LeadFilterFormValues | 'interests';

interface LeadFilterFormProps {
  selectedHouseId?: string;
  blockRequest: (msg: string) => void;
  unblockRequest: () => void;
  leadBlockMessage: string;
  solutionInterests: SolutionOption[];
  setSolutionInterests: any;
  forPartner?: boolean;
  themeColor?: string;
  alwaysAsk?: LeadFilterQuestion[];
  excludeFilters?: LeadFilterQuestion[];
  inQuoteFlow?: boolean;
}

const LeadFilterForm: React.FC<LeadFilterFormProps> = ({
  selectedHouseId,
  blockRequest,
  unblockRequest,
  leadBlockMessage,
  solutionInterests,
  setSolutionInterests,
  forPartner = false,
  themeColor,
  alwaysAsk = [],
  excludeFilters = [],
  inQuoteFlow,
}) => {
  const initialValues: LeadFilterFormValues = useMemo(
    () => ({
      inVve: '',
      isMonument: '',
      isRenting: '',
    }),
    [],
  );
  const [formValues, setFormValues] = useState<LeadFilterFormValues>(initialValues);

  const [getLeadHouse, { data, loading, error }] = useLazyQuery<
    leadHouseById,
    leadHouseByIdVariables
  >(LEAD_HOUSE_BY_ID, { fetchPolicy: 'cache-first' });

  useEffect(() => {
    if (selectedHouseId && !loading && !error)
      void getLeadHouse({ variables: { id: selectedHouseId } });
  }, [getLeadHouse, loading, selectedHouseId, error]);

  const activeHouse = data?.house || undefined;

  useEffect(() => {
    setFormValues(initialValues);
    unblockRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, selectedHouseId]);

  useEffect(() => {
    if (data?.house.vve && formValues.inVve === '') setFormValues({ ...formValues, inVve: 'true' });
  }, [data?.house.vve, formValues]);

  const { constructionYear, type } = activeHouse || {};

  const filterQuestions = useMemo(() => {
    const questions: LeadFilterQuestion[] = [];
    const isApartment = !!type && isBetween(type, 5, 22);
    const isBefore1930 = !!constructionYear && constructionYear < 1930;
    const isAfter1992 = !!constructionYear && constructionYear > 1992;
    const checkMonument = isBefore1930 && !isApartment;

    if (forPartner) {
      if (isApartment) questions.push('inVve');
      if (isBefore1930 || isAfter1992) questions.push('interests');
      if (checkMonument) questions.push('isMonument');
    } else {
      questions.push('isRenting');
    }

    return [...new Set([...questions, ...alwaysAsk])].filter(q => !excludeFilters.includes(q));
  }, [alwaysAsk, constructionYear, excludeFilters, forPartner, type]);

  const solutionsForNewHouses = useMemo(
    () => [
      'all',
      Solution.PvSystem,
      Solution.HybridHeatPump,
      Solution.ElectricHeatPump,
      Solution.WindowGlazing,
      Solution.InnerRoofInsulation,
    ],
    [],
  );

  useEffect(() => {
    if (!constructionYear || !type) return unblockRequest();
    if (filterQuestions.length === 0) return unblockRequest();

    const passVve = !filterQuestions.includes('inVve') || formValues.inVve === 'false';
    const passIsRenting =
      !filterQuestions.includes('isRenting') || formValues.isRenting === 'false';
    const passMonument =
      !filterQuestions.includes('isMonument') || formValues.isMonument === 'false';
    const passInterestsNew =
      !filterQuestions.includes('interests') ||
      constructionYear < 1992 ||
      (solutionInterests.length > 0 &&
        solutionsForNewHouses.some(s => solutionInterests.includes(s as Solution)));
    const passInterestsOld =
      !filterQuestions.includes('interests') ||
      constructionYear >= 1920 ||
      (solutionInterests.length > 0 && !isEqual(solutionInterests, [Solution.WallInsulation]));

    if (passIsRenting && passVve && passMonument && passInterestsNew && passInterestsOld) {
      return unblockRequest();
    }

    let msg = 'default';
    switch (true) {
      case formValues.isRenting === 'true':
        msg = 'We kunnen huurders op dit moment helaas niet helpen met verduurzamen.';
        break;
      case formValues.inVve === 'true':
        msg = `Individuele appartementen en woningen in VvE's kunnen we helaas op dit moment niet helpen verduurzamen. Wilt ${
          forPartner ? 'uw klant' : 'u'
        } advies aanvragen namens een VvE, neem dan contact met ons op.`;
        break;
      case formValues.isMonument === 'true':
        msg = 'Monumenten kunnen we helaas op dit moment niet helpen verduurzamen.';
        break;
      case !passInterestsOld && isEqual(solutionInterests, [Solution.WallInsulation]):
        msg = `We kunnen ${
          forPartner ? 'uw klant' : 'u'
        } bij deze woning helaas niet met spouwmuurisolatie helpen.`;
        break;
      case filterQuestions.includes('interests') && solutionInterests.length === 0:
        msg = 'Kies ten minste 1 oplossing, of selecteer Weet ik niet';
        break;
      case !passInterestsNew &&
        solutionInterests.length > 0 &&
        !solutionsForNewHouses.some(s => solutionInterests.includes(s as Solution)):
        msg = `We kunnen ${
          forPartner ? 'uw klant' : 'u'
        } bij deze woning op dit moment niet specifiek met deze oplossingen helpen. Heeft ${
          forPartner ? 'u of uw klant' : 'u'
        } hierover vragen, neem dan contact met ons op.`;
        break;
      default:
        msg = 'default';
    }

    return blockRequest(msg);
  }, [
    activeHouse,
    formValues,
    filterQuestions,
    solutionInterests,
    constructionYear,
    type,
    unblockRequest,
    solutionsForNewHouses,
    blockRequest,
    forPartner,
  ]);

  const handleChangeInterests = useCallback(
    (solution: SolutionOption) => {
      const newArray = [...solutionInterests];
      if (solution !== 'all' && solutionInterests.includes('all')) {
        newArray.splice(solutionInterests.indexOf('all'), 1);
      }
      if (solutionInterests.includes(solution)) {
        newArray.splice(solutionInterests.indexOf(solution), 1);
      } else {
        newArray.push(solution);
      }
      setSolutionInterests(newArray);
    },
    [setSolutionInterests, solutionInterests],
  );

  const houseOrApartment =
    type && (type === 5 || isBetween(type, 14, 22)) ? 'dit appartement' : 'deze woning';

  const SHOW_DEBUG_INFO = false;
  const debugInfo = (
    <Red>
      <strong>
        <p>Woningtype: {type ? HOUSE_TYPES_NL[type] : 'undefined'}</p>
        <p>Bouwjaar: {constructionYear || 'undefined'}</p>
        <p>Benodigde check: {filterQuestions.join(' + ')}</p>
      </strong>
    </Red>
  );

  return loading ? (
    <Spinner />
  ) : (
    <div>
      {leadBlockMessage && leadBlockMessage !== 'default' && (
        <Large>
          <Orange>
            <p>{leadBlockMessage}</p>
          </Orange>
        </Large>
      )}

      {filterQuestions.length > 1 && !inQuoteFlow && <Separator my={2} color="grayLight" />}

      {SHOW_DEBUG_INFO ? debugInfo : <></>}

      <Small>
        {filterQuestions.includes('isRenting') && (
          <>
            <p>Betreft het een koop- of huurwoning?</p>
            <RadioGroup
              divider={2}
              fontSize={6}
              name="isRenting"
              bgColor={themeColor}
              onChange={(value: NullableBoolean) => {
                setFormValues({ ...formValues, isRenting: value });
              }}
            >
              <Radio
                id="isRenting-false"
                value="false"
                label="Koopwoning"
                checked={formValues.isRenting === 'false'}
              />
              <Radio
                id="isRenting-true"
                value="true"
                label="Huurwoning"
                checked={formValues.isRenting === 'true'}
              />
            </RadioGroup>
          </>
        )}
        {filterQuestions.includes('inVve') && (
          <>
            <p>Is {houseOrApartment} onderdeel van een VvE?</p>
            <RadioGroup
              divider={2}
              fontSize={6}
              bgColor={themeColor}
              onChange={(v: NullableBoolean) => setFormValues({ ...formValues, inVve: v })}
            >
              <Radio
                id={'vve-false'}
                value="false"
                label="Nee"
                checked={formValues.inVve === 'false'}
              />
              <Radio
                id={'vve-true'}
                value="true"
                label="Ja"
                checked={formValues.inVve === 'true'}
              />
            </RadioGroup>
          </>
        )}

        {filterQuestions.includes('isMonument') && (
          <>
            <p>Heeft dit pand een monumentenstatus?</p>
            <RadioGroup
              divider={2}
              fontSize={6}
              bgColor={themeColor}
              onChange={(v: NullableBoolean) => setFormValues({ ...formValues, isMonument: v })}
            >
              <Radio
                id={'monument-false'}
                value="false"
                label="Nee"
                checked={formValues.isMonument === 'false'}
              />
              <Radio
                id={'monument-true'}
                value="true"
                label="Ja"
                checked={formValues.isMonument === 'true'}
              />
            </RadioGroup>
          </>
        )}

        {filterQuestions.includes('interests') && (
          <SolutionChecklist
            onChange={handleChangeInterests}
            disabled={formValues.isMonument === 'true' || formValues.inVve === 'true'}
            checkedSolutions={solutionInterests}
            omittedSolutions={[Solution.CentralHeatingBoiler]}
            fontSize={6}
            color={themeColor}
            hideAllOption={inQuoteFlow}
          />
        )}
      </Small>
    </div>
  );
};

export default LeadFilterForm;
